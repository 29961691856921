.footer{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color:white;
    text-align: left;
    background-size:cover;
    background-position:center;
    background-color: #7b6c5b;
    
    }
    
    .footer.mobile{
        flex-direction: column;
    }
    
    .link{
  
        padding-bottom: 8px;
        font-size: 15px;
        color: white;
        transition: 100ms;
    }
    
    .link:hover{
    transition: 100ms;
      font-size: 20px;
      color: white;
    
    }
    a:hover{
        text-decoration: none;
    }
    

 