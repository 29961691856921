.contact {
  
  position:static;
  height: 90vh;
  background-size:auto;
  background-position:center;
  
  display: flex;
align-items: center;
margin: 10vh;
justify-content: center;

  }

  .input{
    width: 35vw;
    padding: 12px 20px;
    margin-bottom: 10px;
    display: inline-block;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #f1f1f1;
  }

  .input.mobile{
    width: 90vw;
    margin:5vw
  }

  .bgs {
    position:static;
  height: 90vh;
    background-size:cover;
    background-position:center;
    background-image:url('../Assets/bg5.jpg');
  
  }
  .bgs2 {
    position:static;
  height: 80vh;
    background-size:cover;
    background-position:center;
    background-image:url('../Assets/bg4.jpg');
    width: 50vw;
   
  }
  .bgs3 {
    position:static;
  height: 30vh;
    background-size:cover;
    background-position:center;
    background-image:url('../Assets/bg4.jpg');
    width: 90vw;
  
  }




  .banner {
    position:static;
  height:93vh;
    background-size:cover;
    background-position:left;
    background-image:url('../Assets/fluted\ panel\ bg.png');
    width: 100%;
    margin-top: 7vh;
   
  }
    

 