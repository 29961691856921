.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}
 
.bg {
  position:static;
height: 100vh;
  background-size:cover;
  background-position:center;
  background-image:url('./Assets/bg2.png');


}
.image1 {
  position:static;
  height: 90vh;
  background-size:cover;
  background-position:center;
  background-image:url('./Assets/11.jpg');

margin: 5vh;

}

.fluted{
  background-image:url('./Assets/1.jpg');
  height: 50vh;

 background-size:cover;
 
}

.underline-hover-effect{
  display:inline-block;
  position: relative;
 padding-bottom: 4px;
  color:#C19A5B;
  cursor: pointer;

 
}

.underline-hover-effect::before{
  
content: '';
position: absolute;
width: 0;
height: 1px;
bottom: 0;
right: 0;
background-color: #C19A5B;
transition: width .5s ease-out;


}
.underline-hover-effect:hover::before{
  width:100%;
  left: 0;
  right: auto;

}

.bianca{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
 
  background-position: center;
  background-image:url('./Assets/bianca\ fluted\ panel.png');
  transition: 1s;
}

.biancam{
  height: 80vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/bianca\ fluted\ panel.png');
  transition: 1s;
}

.bianca:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}
.oxford{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items:center;
      color: whitesmoke;
  font-weight: bold;
  background-position: center;
  background-image:url('./Assets/oxford\ fluted\ panel.png');
  transition: 1s;
}

.oxfordm{
  height: 80vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/oxford\ fluted\ panel.png');
  transition: 1s;
}


.oxford:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}
.easton{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
   
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/easton\ fluted\ panel.png');
  transition: 1s;
}

.eastonm{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/easton\ fluted\ panel.png');
  transition: 1s;
}

.easton:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}
.delta{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
 
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/delta\ fluted\ panel.png');
  transition: 1s;
}

.deltam{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/delta\ fluted\ panel.png');
  transition: 1s;
}

.delta:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}
.zion{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
  
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/zion\ fluted\ panel.png');
  transition: 1s;
}

.zionm{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/zion\ fluted\ panel.png');
  transition: 1s;
}
.sacha{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
  
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/sacha\ fluted\ panel.png');
  transition: 1s;
}

.sacham{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/sacha\ fluted\ panel.png');
  transition: 1s;
}

.chevron{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
  
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/chevron\ fluted\ panel.png');
  transition: 1s;
}

.chevronm{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/chevron\ fluted\ panel.png');
  transition: 1s;
}

.sublime{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
  
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/sublime\ fluted\ panel.png');
  transition: 1s;
}

.sublimem{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/sublime\ fluted\ panel.png');
  transition: 1s;
}


.brentley{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
  
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/brentley\ fluted\ panel.png');
  transition: 1s;
}

.brentleym{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/brentley\ fluted\ panel.png');
  transition: 1s;
}

.sorrento{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
      display: flex;
      justify-content: center;
      align-items: center;
  
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/sorrento\ fluted\ panel.png');
  transition: 1s;
}

.sorrentom{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;

  background-position: center;
  background-image:url('./Assets/sorrento\ fluted\ panel.png');
  transition: 1s;
}



.zion:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.sublime:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.sacha:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.sorrento:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.brentley:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.chevron:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}




.copper{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
     
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/copper\ fluted\ panel.png');
  transition: 1s;
}


.copperm{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
  
  background-position: center;
  
  background-image:url('./Assets/copper\ fluted\ panel.png');
  transition: 1s;
}

.copper:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.edison{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
     
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/edison\ fluted\ panel.png');
  transition: 1s;
}

.edisonm{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
  
  background-position: center;
  
  background-image:url('./Assets/edison\ fluted\ panel.png');
  transition: 1s;
}

.edison:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}
.cove{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
     
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/cove\ fluted\ panel.png');
  transition: 1s;
}

.covem{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
  
  background-position: center;
  
  background-image:url('./Assets/cove\ fluted\ panel.png');
  transition: 1s;
}

.cove:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}
.oakley{
  height: 20vw;
  width: 25vw;
  letter-spacing: 10px;
      font-size: x-large;
     
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
   
  background-position: center;
  background-image:url('./Assets/oakley\ fluted\ panel.png');
  transition: 1s;
}

.oakleym{
  height: 90vw;
  width: 90vw;
  letter-spacing: 5px;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      font-weight: bolder;
  
  background-position: center;
  background-image:url('./Assets/oakley\ fluted\ panel.png');
  transition: 1s;
}


.oakley:hover{
  transform: scale(1.2);
  letter-spacing: 0px;
  transition: 1s;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
